import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
const FileDropZone = (props) => {
	const [fileData, setFileData] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();
			setSelectedFile(file);
			props.parentCallBack(file);
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setFileData(reader.result);
			};
		});
	}, []);
	React.useEffect(() => {
		if (!props.selectedFile) {
			setFileData(null);
			setSelectedFile(null);
		}
	}, [props.selectedFile]);
	const { getRootProps, getInputProps } = useDropzone({ disabled: !props.enableUploading, onDrop, multiple: false, maxSize: 5242880, accept: "image/jpeg,image/png,image/jpg,application/pdf" });
	const handleColse = (e) => {
		setFileData(null);
		setSelectedFile(null);
		props.parentCallBack(null);
		e.stopPropagation();
	};
	return (
		<Container>
			<Row className="justify-content-md-center">
				<Col md={10} {...getRootProps()} className="text-center dropzone-div">
					<input {...getInputProps()} accept="" />
					{fileData ? (
						<div className="placeholder text-center">
							{selectedFile.type === "application/pdf" ? selectedFile.name : <img src={fileData} width="90%" style={{ maxHeight: "100%" }} alt="receipt" />}{" "}
							<span className="closeHref" onClick={handleColse}>
								<img className="closeImg" src="/public/webstatic/images/closeIcon.png" />
							</span>
						</div>
					) : (
						<p>
							{!props.enableUploading ? (
								<>
									<Trans>Sorry</Trans>! <Trans>You have reached the program limit for rewards</Trans>.
								</>
							) : (
								<>
									<Trans>Click here to upload receipt</Trans>.
									<br />
									<Trans>Please select one image</Trans>.
									<br />
									<Trans>JPG, PNG, or PDF are accepted</Trans>.
								</>
							)}
						</p>
					)}
				</Col>
			</Row>
		</Container>
	);
};
export default FileDropZone;
