import React from "react";
import { Route, Switch } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
//import Signup from "../screen/Signup";
import Register from "../screen/Signup/Register";
//import Home from "../screen/Home/MicroSiteHome";
import Home from "../screen/Home";
import Login from "../screen/Login";
import AboutUs from "../screen/Static/AboutUs";
import ContactUs from "../screen/Static/ContactUs";
import PrivatePocily from "../screen/Static/PrivatePolicy";
import ForgetPassword from "../screen/Auth/ForgetPassword";
import ResetPassword from "../screen/Auth/ResetPassword";
import ConfirmAccount from "../screen/Auth/ConfirmAccount";
import EarnPointsPreview from "../screen/Earnpoints/EarnPointsPreview";
import PointsHistory from "../screen/PointsHistory";
import PrivateRoute from "./PrivateRoute";
import Profile from "../screen/Profile";
import QuizThankuLandingPage from "../screen/Earnpoints/QuizThankuLandingPage";
import UploadReceipt from "../screen/ValidatePurchase/UploadReceipt";
import ValidateCode from "../screen/ValidatePurchase/ValidateCode";
import EligibleProduct from "../screen/Product/EligibleProduct";
import Rewards from "../screen/Rewards";
import RewardsPreview from "../screen/Rewards/RewardsPreview";
import RewardDownload from "../screen/Rewards/RewardDownload";
import MyRewards from "../components/rewards/MyRewards";
import EarnPoints from "../screen/Earnpoints/EarnPoints";
import HelpDesk from "../screen/HelpDesk/HelpDesk";
import HelpDeskTicket from "../screen/HelpDesk/HelpDeskTicket";
import ContestPreview from "../screen/Contest/ContestPreview";
import Contest from "../screen/Contest";
import ContestXPreview from "../screen/Contest/ContestXPreview";
import Upcoming from "../screen/CampaignStatus/Upcoming";
import Expired from "../screen/CampaignStatus/Expired";
import SupportScreen from "../screen/SupportScreen";
const routes = (
	<>
		<Switch>
			<AppRoute exact path="/" component={Home} layout={PublicLayout} />
			{/* <AppRoute exact path="/register" component={Signup} layout={PublicLayout} /> */}
			<AppRoute exact path="/register" component={Register} layout={PublicLayout} />
			<AppRoute exact path="/login" component={Login} layout={PublicLayout} />
			<AppRoute exact path="/about-us" component={AboutUs} layout={PublicLayout} />
			<AppRoute exact path="/contact-us" component={ContactUs} layout={PublicLayout} />
			<AppRoute exact path="/private-policy" component={PrivatePocily} layout={PublicLayout} />
			<AppRoute exact path="/forget-password" component={ForgetPassword} layout={PublicLayout} />
			<AppRoute exact path="/reset-password" component={ResetPassword} layout={PublicLayout} />
			<AppRoute exact path="/confirm-account" component={ConfirmAccount} layout={PublicLayout} />
			<PrivateRoute exact path="/points-history" component={PointsHistory} layout={PublicLayout} />
			<PrivateRoute exact path="/earnpoints" component={EarnPoints} layout={PublicLayout} />
			<PrivateRoute exact path="/earnpoints-action-preview/:id" component={EarnPointsPreview} layout={PublicLayout} />
			<PrivateRoute exact path="/profile" component={Profile} layout={PublicLayout} />
			<PrivateRoute exact path="/upload-receipt" component={UploadReceipt} layout={PublicLayout} />
			<PrivateRoute exact path="/validate-code" component={ValidateCode} layout={PublicLayout} />
			<PrivateRoute exact path="/eligible-product" component={EligibleProduct} layout={PublicLayout} />
			<PrivateRoute exact path="/rewards" component={Rewards} layout={PublicLayout} />
			<PrivateRoute exact path="/reward-preview/:id" component={RewardsPreview} layout={PublicLayout} />
			<PrivateRoute exact path="/my-rewards" component={MyRewards} layout={PublicLayout} />
			<PrivateRoute exact path="/help-desk" component={HelpDesk} layout={PublicLayout} />
			<PrivateRoute exact path="/help-desk/:id" component={HelpDeskTicket} layout={PublicLayout} />
			<PrivateRoute exact path="/download/activity-sheet/:id/:filename" component={RewardDownload} layout={PublicLayout} />
			<PrivateRoute exact path="/contest/:id" component={ContestPreview} layout={PublicLayout} />
			<PrivateRoute exact path="/contest" component={Contest} layout={PublicLayout} />
			<Route exact path="/contestx/:id" component={ContestXPreview} />
			<Route exact path="/static/thank-you-quiz" component={QuizThankuLandingPage} layout={PublicLayout} />
			<Route exact path="/upcoming" component={Upcoming} />
			<Route exact path="/expired" component={Expired} />
			<AppRoute exact path="/support" component={SupportScreen} layout={PublicLayout} />
		</Switch>
	</>
);
export default routes;
