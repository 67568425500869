import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { ticketsAction } from "../../redux/actions";
import { useParams, Redirect } from "react-router-dom";
const TicketDetails = (props) => {
	let [ticketDet, setTicketDet] = useState({});
	let [idIsValid, setIdIsValid] = useState(true);
	let { id } = useParams();
	useEffect(() => {
		props.setLoading(true);
		ticketsAction(id)
			.then((response) => {
				if (response.data) {
					let ticketData = response.data;
					if (typeof ticketData.replies === "object") {
						let temp = ticketData.replies.filter((v) => v.threadType === "message");
						ticketData.replies = temp;
					}
					setTicketDet(ticketData);
				} else {
					setIdIsValid(false);
				}
				props.setLoading(false);
			})
			.catch((ex) => {
				setIdIsValid(false);
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	}, [id]);
	if (!idIsValid) {
		return <Redirect push to="/help-desk" />;
	} else {
		return (
			<Container className="help-desk-container">
				<Container fluid>
					<Container fluid>
						<Container fluid>
							<div className="header-parent-label">
								<Link id="headerParentLink" className="parent-link" to="/help-desk">
									<Trans>Help Desk</Trans>
								</Link>{" "}
								<Trans>Ticket</Trans> <Trans>Details</Trans>
							</div>
							<h5 className="text-center">
								<b>
									<Trans>Ticket</Trans>: {id} &#8658;{" "}
								</b>
								{ticketDet.ticketDetails ? ticketDet.ticketDetails.subject : ""}
								<br />
								<br />
							</h5>
							<Container className="border border-success">
								<br />
								{ticketDet.replies
									? ticketDet.replies.map((v, i) => {
											return (
												<Row className={v.createdBy.type === "customers" ? "justify-content-end" : "justify-content-start"}>
													<Col xs lg="8">
														<Card>
															<Card.Body>
																<Card.Title>
																	<Alert variant={v.createdBy.type === "customers" ? "success" : "warning"}>{v.createdBy.type === "customers" ? "You" : "Agent"}</Alert>
																</Card.Title>
																{/* <Card.Text dangerouslySetInnerHTML={{ __html: v.htmlBody.indexOf(`div class=\"signature\"`) >= 0 ? v.htmlBody.substring(0, v.htmlBody.indexOf(`div class=\"signature\"`) - 1) : v.htmlBody }} /> */}
																<Card.Text dangerouslySetInnerHTML={{ __html: v.htmlBody }} />
																<Card.Subtitle className="mb-2 text-muted text-right">{new Date(v.createdAt).toLocaleString()}</Card.Subtitle>
															</Card.Body>
														</Card>
													</Col>
												</Row>
											);
									  })
									: ""}
							</Container>
						</Container>
					</Container>
				</Container>
			</Container>
		);
	}
};

export default IsLoadingHOC(TicketDetails, "Loading....");
