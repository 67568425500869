import React from "react";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import MicrosoftLogin from "react-microsoft-login";
import AppleSignin from "react-apple-signin-auth";
import { connect } from "react-redux";
import { facebookLogin, googleLoign, microsoftLogin, appleLoignAction } from "../redux/actions";
import IsLoadingHOC from "./IsLoadingHOC";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Container, Modal } from "react-bootstrap";
import OAuthRegistration from "./OAuthRegistration";
import { store } from "../redux/store";
class SocialLogin extends React.Component {
	constructor(props) {
		super(props);
		const config = store.getState("app").app.config ? store.getState("app").app.config : {};
		this.state = {
			error: null,
			name: "",
			accessToken: "",
			email: "",
			roles: [],
			app: "",
			showModel: false,
			userData: {},
			updateId: "",
			msalInstance: "",
			error: "",
			designProp: { height: 40 },
			campaignConfig: config,
		};
		this.responseApple = this.responseApple.bind(this);
	}
	responseFacebook = (resp) => {
		const { t } = this.props;
		const requestOptions = {
			id: resp.id,
			email: resp.email,
			name: resp.name,
			accessToken: resp.accessToken,
		};
		this.props.setLoading(true);
		this.props
			.facebookLogin(requestOptions)
			.then((response) => {
				if (response.httpCode === 202) {
					let oldStat = {
						...this.state,
						showModel: true,
						userData: response.data,
						updateId: "facebook",
					};
					this.setState(oldStat);
					this.props.setLoading(false);
				} else {
					this.props.setLoading(false);
					this.props.history.push("/");
					toast.success(t("User login successfully"));
				}
			})
			.catch((error) => {
				console.log("err", error);
				this.props.setLoading(false);
			});
	};
	responseGoogle = (resp) => {
		const { t } = this.props;
		const requestOptions = {
			id: resp.googleId,
			email: resp.profileObj ? resp.profileObj.email : "",
			name: resp.profileObj ? resp.profileObj.name : "",
			accessToken: resp.accessToken,
		};
		this.props.setLoading(true);
		this.props
			.googleLoign(requestOptions)
			.then((response) => {
				if (response.httpCode === 202) {
					let oldStat = {
						...this.state,
						showModel: true,
						userData: response.data,
						updateId: "google",
					};
					this.setState(oldStat);
					this.props.setLoading(false);
				} else {
					this.props.setLoading(false);
					this.props.history.push("/");
					toast.success(t("User login successfully"));
				}
			})
			.catch((error) => {
				console.log("err", error);
				this.props.setLoading(false);
			});
	};
	responseMicrosoft = (err, resp, msal) => {
		const { t } = this.props;
		try {
			if (!err && resp) {
				this.setState({ msalInstance: msal });
			}

			const requestOptions = {
				id: resp.idToken.objectId,
				email: resp.account.userName ? resp.account.userName : "",
				name: resp.account.name ? resp.account.name : "",
				accessToken: resp.accessToken,
			};
			this.props.setLoading(true);
			this.props
				.microsoftLogin(requestOptions)
				.then((response) => {
					if (response.httpCode === 202) {
						let oldStat = {
							...this.state,
							showModel: true,
							userData: response.data,
							updateId: "microsoft",
						};
						this.setState(oldStat);
						this.props.setLoading(false);
					} else {
						this.props.setLoading(false);
						this.props.history.push("/");
						toast.success(t("User login successfully"));
					}
				})
				.catch((error) => {
					console.log("err", error);
					this.props.setLoading(false);
				});
		} catch (error) {
			console.log("err", error);
			this.props.setLoading(false);
		}
	};
	parseJwt = (token) => {
		try {
			return JSON.parse(atob(token.split(".")[1]));
		} catch (e) {
			return null;
		}
	};
	responseApple(resp) {
		console.log("hhh", resp);
		if (resp.authorization) {
			const { t } = this.props;
			const tokenAppleData = this.parseJwt(resp.authorization.id_token);
			const requestOptions = {
				id: resp.authorization.id_token,
				email: tokenAppleData ? tokenAppleData.email : "",
				code: resp.authorization.code,
			};
			this.props.setLoading(true);
			this.props
				.appleLoignAction(requestOptions)
				.then((response) => {
					if (response.httpCode === 202) {
						let oldStat = {
							...this.state,
							showModel: true,
							userData: response.data,
							updateId: "apple",
						};
						this.setState(oldStat);
						this.props.setLoading(false);
					} else {
						this.props.setLoading(false);
						this.props.history.push("/");
						toast.success(t("User login successfully"));
					}
				})
				.catch((error) => {
					console.log("err", error);
					this.props.setLoading(false);
				});
		}
	}
	render() {
		const { error } = this.state;
		const { t } = this.props;
		if (error) {
			return <div>Error: {error.message}</div>;
		} else {
			return (
				<Container>
					<section className="social-login">
						{this.state.campaignConfig.facebookAuthentication && this.state.campaignConfig.facebookAuthentication.appId ? (
							<div className="facebook-login">
								<FacebookLogin textButton={t("Login with Facebook")} appId={this.state.campaignConfig.facebookAuthentication.appId} autoLoad={false} fields="name,email,picture" scope="public_profile,email" callback={this.responseFacebook} />
							</div>
						) : (
							""
						)}
						{this.state.campaignConfig.googleAuthentication && this.state.campaignConfig.googleAuthentication.clientId ? (
							<div className="google-login">
								<GoogleLogin buttonText={t("login")} clientId={this.state.campaignConfig.googleAuthentication.clientId} onSuccess={this.responseGoogle} onFailure={this.responseGoogle} cookiePolicy={"single_host_origin"} />
							</div>
						) : (
							""
						)}
						{this.state.campaignConfig.appleAuthentication && this.state.campaignConfig.appleAuthentication.clientId ? (
							<div className="apple-login">
								<AppleSignin
									authOptions={{
										clientId: this.state.campaignConfig.appleAuthentication.clientId,
										scope: "email name",
										redirectURI: this.state.campaignConfig.appleAuthentication.redirectURI,
										state: `${new Date().getTime()}`,
										nonce: "nonce",
										usePopup: true,
									}} // REQUIRED
									uiType="dark"
									className="apple-auth-btn"
									noDefaultStyle={false}
									buttonExtraChildren={t("login")}
									onSuccess={this.responseApple} // default = undefined
									onError={(error) => console.log(",,,,,,,,,,,,,,", error)} // default = undefined
									skipScript={false} // default = undefined
								/>
							</div>
						) : (
							""
						)}
						{this.state.campaignConfig.microsoftAuthentication && this.state.campaignConfig.microsoftAuthentication.clientId ? (
							<div className="microsoft-login">{!this.state.msalInstance ? <MicrosoftLogin buttonText={t("login")} clientId={this.state.campaignConfig.microsoftAuthentication.clientId} authCallback={this.responseMicrosoft} cookiePolicy={"single_host_origin"} /> : ""}</div>
						) : (
							""
						)}

						<Modal size="xl" show={this.state.showModel} animation={false}>
							<Modal.Body>
								<OAuthRegistration userData={this.state.userData} updateId={this.state.updateId} />
							</Modal.Body>
						</Modal>
					</section>
				</Container>
			);
		}
	}
}

export default connect(null, { facebookLogin, googleLoign, microsoftLogin, appleLoignAction })(IsLoadingHOC(withRouter(withTranslation()(SocialLogin))));
